import "./src/styles/global.css"

// gatsby-browser.js
export const onClientEntry = () => {
	// Função para verificar o cookie zipdev-ignore
	const shouldLoadGTM = () => {
		if (typeof document !== 'undefined') {
			console.log('Verificando cookie...');
			const cookie = document.cookie.split('; ').find(row => row.startsWith('zipdev-ignore='));
			return !cookie; // Carrega o GTM se o cookie não existir
		}
		return false; // Não carrega o GTM se document não estiver definido
	};

	// console.log('Resultado de shouldLoadGTM:', shouldLoadGTM());

	// Carrega o Google Tag Manager se necessário
	if (shouldLoadGTM() && typeof window !== 'undefined') {
		// console.log('Carregando GTM...');
		// GTM Script
		(function(w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l !== 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MF8JR2RG');

		// Google Ads (gtag) Script
		(function(w, d, s) {
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s);
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11093508342';
			f.parentNode.insertBefore(j, f);
			
			w.dataLayer = w.dataLayer || [];
			// Definir gtag globalmente
			w.gtag = function(){w.dataLayer.push(arguments);};
			w.gtag('js', new Date());
			w.gtag('config', 'AW-11093508342');

			// Função para verificar a URL e disparar eventos específicos
			function checkURLAndTriggerEvents() {
				const currentPath = window.location.pathname;
				
				if (currentPath.includes('/savings-calculator')) {
					if (currentPath.includes('/thank-you')) {
						w.gtag('event', 'savings-calculator-thank-you', {
							'send_to': 'AW-11093508342'
						});
						// console.log('Evento saving-calculator-thank-you disparado');
					} else {
						w.gtag('event', 'savings-calculator', {
							'send_to': 'AW-11093508342'
						});
						// console.log('Evento savings-calculator disparado');
					}
				}
			}

			// Executa a verificação quando a página carrega
			checkURLAndTriggerEvents();
		})(window, document, 'script');
	} else {
		// console.log('GTM e gtag não foram carregados.');
	}
};

// Adiciona o handler de atualização de rota do Gatsby
export const onRouteUpdate = () => {
	// console.log('onRouteUpdate disparado');
	// console.log('window.gtag exists:', typeof window !== 'undefined' && !!window.gtag);
	if (typeof window !== 'undefined' && window.gtag) {
		// console.log('window.gtag disponível');
		const currentPath = window.location.pathname;
		// console.log('currentPath:', currentPath);
		
		if (currentPath.includes('/savings-calculator')) {
			if (currentPath.includes('/thank-you')) {
				window.gtag('event', 'savings-calculator-thank-you', {
					'send_to': 'AW-11093508342'
				});
				// console.log('Evento saving-calculator-thank-you disparado na mudança de rota');
			} else {
				window.gtag('event', 'savings-calculator', {
					'send_to': 'AW-11093508342'
				});
				// console.log('Evento savings-calculator disparado na mudança de rota');
			}
		}
	}
};
